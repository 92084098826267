<div class="footer" id="contact">
  <div class="contact">
    <div class="contact-data">
      <h2>DANE KONTAKTOWE:</h2>
      <h3>YAKOO GROUP Jakub Zaręba</h3> 
      <h4> <a href="mailto:biuro@yakoogroup.com"> <i class="fas fa-envelope"></i> biuro@yakoogroup.com</a></h4> 
      <h4> <a href="tel:+48692664079"> <i class="fas fa-phone"></i> +48 692-664-079</a></h4> 

    </div>
    <div class="logo">
      <img src="../../assets/yakoo_logo_big.png" >      
    </div>
  </div>
</div>
<div class="yakoo">
  YAKOO GROUP 2021 | All rights reserved
</div>
