<app-topbar [pageType]="pageType"></app-topbar>
<div class="container">
  <app-spinner *ngIf="showSpinner"></app-spinner>
  <div class="head-section">
    <ngx-gallery
      class="gallery"
      [options]="galleryOptions"
      [images]="galleryImages"
    >
      <app-spinner *ngIf="showImagesSpinner"></app-spinner>
    </ngx-gallery>
    <div class="info">
      <div class="title">{{ car.brand }} {{ car.model }}</div>
      <div class="price">
        {{ car.status == "sold" ? "SPRZEDANE" : car.price + " zł" }}
      </div>
      <div>
        <span>Rocznik: {{ car.year }}</span>
      </div>
      <div>
        <span>Przebieg: {{ car.odometer }} km</span>
      </div>
      <div>
        <span>Pojemność: {{ car.engine }} cm<sup>3</sup></span>
      </div>
      <div>
        <span>Moc: {{ car.horsePower }} KM</span>
      </div>
      <div>
        <span>Rodzaj paliwa: {{ car.fuelType }}</span>
      </div>
      <div>
        <span>Skrzynia biegów: {{ car.gearbox }}</span>
      </div>
      <div>
        <span>Typ nadwozia: {{ car.bodyType }}</span>
      </div>
      <div>
        <span>Liczba drzwi: {{ car.doorCount }}</span>
      </div>
    </div>
    <br />
  </div>
  <div class="line"></div>
  <div class="equipment">
    <h3>WYPOSAŻENIE</h3>
    <div class="equip" *ngFor="let item of car.equipment">
      <span><i class="fas fa-car-alt"></i>{{ item }}</span>
    </div>
  </div>
  <div class="line"></div>

  <div class="description" [innerHTML]="car.description"></div>
</div>
