<div class="car" (click)=editCar() >
  <span (click)="$event.stopPropagation()">
    <div class="delete-btn" (click)="deleteCarDialog()">USUŃ OGŁOSZENIE</div>
  </span>

  <div class="img-container">
    <img src={{car.mainImgUrl}} alt="">
  </div>
  <div class="car-info">
    <div class="car-model">{{car.brand}} {{car.model}}</div>
    <div>{{car.year}} | {{car.odometer}} km | {{car.bodyType}}</div>
    <div class="car-price">{{car.price}} zł</div>
    <div class="badge">
      <div *ngIf="car.status == 'reserved'" class="reserved">Zarezerwowane</div>
      <div *ngIf="car.status == 'sold'" class="sold">SPRZEDANE</div>
      <div *ngIf="!car.isArchived" class="active">AKTYWNE</div>
      <div *ngIf="car.isArchived" class="inactive">NIEAKTYWNE</div>
    </div>
  </div>
</div>
