<div class="slideshow"  (scroll)="scrollHandler()">
  <div class="darken"></div>
  <div class="header-text">
    <span class="title">YAKOO GROUP</span>
    Sprzedaż samochodów używanych
  </div>
  <div class="scroll-down-text">
    Przewiń niżej, aby dowiedzieć się więcej<br><i class="fas fa-chevron-down"></i>
  </div>
</div>
